.valuesContainer,
.teamContainer {
  margin-bottom: 96px;
}
.valueMainSection {
  display: flex;
  justify-content: space-between;
}
.valueMainSection .textContainer {
  padding-left: 96px;
  padding-right: 64px;
  padding-top: 52px;
  width: 800px;
  flex-shrink: 0;
}
.valueMainSection .imageContainer {
  width: calc(100% - 800px);
  height: 768px;
  flex-shrink: 1;
}
.valueMainSection .imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.valueMainSection .textContainer h2 {
  font-weight: 500;
  font-size: 54px;
  color: var(--DeltaBlue);
  margin-bottom: 52px;
}
.valueMainSection .textContainer p {
  font-size: 19px;
  line-height: 30px;
  margin-bottom: 32px;
}
.valuesLists {
  display: flex;
  justify-content: space-around;
  padding-left: 96px;
  padding-right: 96px;
  padding-top: 88px;
}
.valuesLists h3 {
  font-weight: 500;
  font-size: 54px;
  line-height: 40px;
  color: var(--DeltaBlue);
  margin-bottom: 64px;
}
.valuesLists ul {
  list-style-type: none;
  width: 400px;
}
.valuesLists ul li {
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  margin-bottom: 52px;
}
.valuesLists ul li::before {
  display: inline-block;
  content: "";
  width: 12px;
  height: 12px;
  background-color: var(--DeltaBlue);
  border-radius: 50%;
  margin-right: 20px;
}

.sectionText {
  padding-top: 35px;
  padding-bottom: 35px;
  margin-top: 0px;
  border-bottom: #d9d9d9 4px solid;
  padding-left: 96px;
  padding-right: 32px;
  background-color: #95c1d5;
}
.sectionText > h2,
.sectionText > h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 54px;
  color: #fff;
  margin-bottom: 20px;
}
.sectionText > p {
  font-weight: 400;
  font-size: 24px;
  line-height: 34px;
  color: #fff;
}
@media only screen and (max-width: 1150px) {
  .valueMainSection .textContainer {
    padding-left: 64px;
    padding-right: 40px;
    width: 576px;
  }
  .servMainSection .textContainer h2 {
    font-size: 38px;
  }
  .valueMainSection .imageContainer {
    width: calc(100% - 576px);
    height: 800px;
  }
}
@media only screen and (max-width: 950px) {
  .valueMainSection {
    flex-direction: column-reverse;
  }
  .valueMainSection .textContainer {
    width: auto;
    padding-left: 64px;
    padding-right: 64px;
    padding-top: 64px;
  }
  .valueMainSection .imageContainer {
    width: auto;
    height: 352px;
  }
  .valueMainSection .textContainer h2 {
    font-size: 48px;
  }
  .valueMainSection .textContainer p {
    font-size: 16px;
    line-height: 30px;
  }
  .valuesLists {
    padding-left: 64px;
    padding-right: 64px;
    padding-top: 64px;
    justify-content: space-between;
  }
  .valuesLists h3 {
    font-size: 38px;
  }
  .valuesLists ul {
    width: 310px;
  }
  .valuesLists ul li {
    font-size: 12px;
  }
  .valuesLists ul li::before {
    width: 8px;
    height: 8px;
  }
  .sectionText {
    padding-left: 64px;
  }
  .sectionText > p {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 64px;
  }
}
@media only screen and (max-width: 600px) {
  .valueMainSection .textContainer {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 40px;
  }
  .valueMainSection .imageContainer {
    height: 256px;
  }
  .valueMainSection .textContainer h2 {
    font-size: 30px;
  }
  .valuesLists {
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 64px;
    padding-right: 0px;
    padding-top: 64px;
  }
  .valuesLists h3 {
    margin-bottom: 56px;
  }
  .sectionText {
    padding-left: 32px;
    padding-right: 32px;
  }
  .sectionText > h2,
  .sectionText > h1 {
    font-size: 38px;
    text-align: center;
  }
  .sectionText > p {
    font-weight: 400;
    margin-bottom: 40px;
    text-align: center;
  }
}
