.formContainer {
  width: 1248px;
  background-color: var(--DarkBlue);
  border-radius: 15px;
  margin: 64px auto;
  overflow: hidden;
}
.formContainer .resumeForm {
  display: grid;
  grid-template-columns: 800px 448px;
  grid-template-rows: repeat(5, 96px) 128px;
  grid-template-areas:
    "a f"
    "b f"
    "c f"
    "d f"
    "e f"
    "g f";
}
.formContainer .resumeForm label:not(.f) {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-bottom: solid #fff 4px;
  padding-left: 64px;
  padding-right: 64px;
}
.formContainer .resumeForm input,
::placeholder {
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: #ffffff;
  background-color: transparent;
  border: none;
}
.formContainer .resumeForm input {
  height: 64px;
}
input:-webkit-autofill {
  background-color: var(--LightBlue) !important;
}
input:autofill {
  background-color: var(--LightBlue) !important;
}
textarea {
  resize: none;
  background-color: transparent;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: #ffffff;
  border: none;
}
.a {
  grid-area: a;
}
.b {
  grid-area: b;
}
.c {
  grid-area: c;
}
.d {
  grid-area: d;
}
.e {
  grid-area: e;
}
.f {
  grid-area: f;
  background-color: #333545;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 64px;
}
.f #auxContainer {
  width: 100%;
}
.f #auxContainer #resume {
  margin-top: 96px;
  overflow: visible;
  height: 30px;
  text-align: center;
  width: inherit;
}
.formContainer .f input {
  background-color: #333545;
}
input::file-selector-button {
  display: none;
}
.f > p {
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 88px;
}
.f > img {
  width: 128px;
  height: 128px;
  transform: rotate(180deg);
}
.f:hover > img {
  border: solid 4px var(--LightBlue);
  box-sizing: content-box;
  border-radius: 50%;
}
.g {
  grid-area: g;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.formContainer .resumeForm .g > input {
  background: #ffffff;
  border-radius: 5px;
  width: 128px;
  height: 32px;
  margin-right: 288px;
  color: var(--DarkBlue);
  font-weight: 700;
}
.formContainer .resumeForm .g > input:hover {
  box-sizing: content-box;
  border: solid 4px var(--LightBlue);
}
@media only screen and (max-width: 1300px) {
  .formContainer {
    width: 896px;
  }
  .formContainer .resumeForm {
    grid-template-columns: 576px 320px;
  }
  .g {
    grid-area: g;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .formContainer .resumeForm .g > input {
    margin-right: 224px;
  }
}
@media only screen and (max-width: 950px) {
  .formContainer {
    width: 640px;
  }
  .formContainer .resumeForm {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 96px) 168px 128px;
    grid-template-areas:
      "a"
      "b"
      "c"
      "d"
      "e"
      "f"
      "g";
  }
  .formContainer .resumeForm input,
  ::placeholder {
    font-size: 16px;
  }
  textarea {
    font-size: 16px;
  }
  .f {
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr;
    grid-template-rows: 48px 32px;
    grid-template-areas:
      "texto logo"
      "archivo archivo";
    padding: 24px 64px;
    margin-top: 32px;
  }
  .f #auxContainer {
    grid-area: archivo;
    width: 100%;
  }
  .f #auxContainer #resume {
    margin-top: 0px;
  }
  .f > p {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0;
    text-align: start;
  }
  .f > img {
    width: 48px;
    height: 48px;
  }
  .formContainer .resumeForm .g > input {
    margin: 0 auto;
  }
}
@media only screen and (max-width: 600px) {
  .formContainer {
    width: 95%;
    max-width: 344px;
    padding-top: 16px;
  }
  .formContainer .resumeForm {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, 64px) 168px 128px;
  }
  .formContainer .resumeForm label:not(.f) {
    padding-left: 32px;
    padding-right: 32px;
  }
  .formContainer .resumeForm input {
    height: 40px;
  }
  textarea {
    height: 32px;
  }
  .f {
    row-gap: 16px;
  }
}
