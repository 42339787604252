.servMainSection {
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
}
.servMainSection .textContainer {
  padding-left: 96px;
  padding-right: 64px;
  padding-top: 52px;
  width: 800px;
  flex-shrink: 0;
}
.servMainSection .textContainer h1 {
  font-weight: 500;
  font-size: 54px;
  color: var(--DeltaBlue);
  margin-bottom: 52px;
}
.servMainSection .textContainer h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 32px;
}
.servMainSection .textContainer p {
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 32px;
}
.servMainSection .imageContainer {
  width: calc(100% - 800px);
  height: 768px;
  flex-shrink: 1;
}
.servMainSection .imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.servTeamSection {
  padding-left: 96px;
  padding-right: 96px;
}
.servTeamSection h2 {
  font-size: 38px;
  font-weight: 500;
  color: var(--DeltaBlue);
  margin-bottom: 64px;
}
.servTeamSection .serviceTeamGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 128px;
  row-gap: 64px;
}
.servTeamSection .serviceTeamGrid > div {
  width: 330px;
  height: 96px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
}
.servTeamSection .serviceTeamGrid > div:hover{
  cursor: pointer;
}
.servTeamSection .serviceTeamGrid > div:hover .text .name{
  color: var(--LightBlue);
  font-weight: 700;
}
.servTeamSection .serviceTeamGrid > div:hover .text .role{
  color: var(--LightBlue);
}
.servTeamSection .serviceTeamGrid > div:hover img{
  border: 4px var(--LightBlue) solid;
}

.servTeamSection .serviceTeamGrid > div > img {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  margin-right: 32px;
  display: flex;
  flex-direction: row-reverse;
}
.servTeamSection .serviceTeamGrid > div .text .name {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}
.servTeamSection .serviceTeamGrid > div .text .role {
  font-size: 16px;
  line-height: 20px;
}
/* ------ */
.servTeamSection .serviceLink {
  width: 738px;
  height: 64px;
  margin: 60px auto;
  border-color: var(--DeltaBlue);
}
.serviceLink:hover{
  background-color: #95c1d5;
}
.servTeamSection .serviceLink p {
  color: var(--DeltaBlue);
}
/* ------ */
@media only screen and (max-width: 1150px) {
  .servMainSection .textContainer {
    padding-left: 64px;
    padding-right: 40px;
    width: 576px;
  }
  .servMainSection .textContainer h1 {
    font-size: 38px;
  }
  .servMainSection .imageContainer {
    width: calc(100% - 576px);
    height: 800px;
  }
  .servTeamSection {
    padding-left: 64px;
    padding-right: 64px;
  }
  .servTeamSection .serviceTeamGrid {
    row-gap: 64px;
    column-gap: 0px;
    justify-content: space-between;
  }
  .servTeamSection .serviceTeamGrid > div {
    width: 272px;
  }
  .servTeamSection .serviceTeamGrid > div .text .name {
    font-size: 14px;
    margin-bottom: 16px;
  }
  .servTeamSection .serviceTeamGrid > div .text .role {
    font-size: 14px;
  }
}
@media only screen and (max-width: 950px) {
  .servMainSection {
    flex-direction: column-reverse;
  }
  .servMainSection .textContainer {
    width: auto;
    padding-left: 64px;
    padding-right: 64px;
    padding-top: 64px;
  }
  .servMainSection .textContainer h1 {
    font-size: 48px;
  }
  .servMainSection .textContainer h3 {
    font-size: 20px;
  }
  .servMainSection .textContainer p {
    font-size: 14px;
    line-height: 30px;
  }
  .servMainSection .imageContainer {
    height: 352px;
    width: auto;
  }
  .servTeamSection {
    padding-left: 64px;
    padding-right: 64px;
  }
  .servTeamSection .serviceTeamGrid > div .text .name {
    font-size: 14px;
  }
  .servTeamSection .serviceTeamGrid > div .text .role {
    font-size: 14px;
  }
  .servTeamSection .serviceLink {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .servMainSection .textContainer {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 40px;
  }
  .servMainSection .textContainer h1 {
    font-size: 30px;
  }
  .servMainSection .imageContainer {
    height: 256px;
  }
  .servTeamSection {
    padding-left: 27px;
    padding-right: 27px;
  }
  .servTeamSection h2 {
    font-size: 24px;
    margin-bottom: 64px;
  }
  .servTeamSection .serviceTeamGrid > div {
    width: 180px;
    height: 64px;
  }
  .servTeamSection .serviceTeamGrid > div > img {
    width: 64px;
    height: 64px;
    margin-right: 10px;
  }
  .servTeamSection .serviceTeamGrid > div .text .name {
    font-size: 10px;
    margin-bottom: 4px;
  }
  .servTeamSection .serviceTeamGrid > div .text .role {
    font-size: 10px;
  }
  .servTeamSection .serviceLink {
    width: auto;
  }
}
