.superNav {
  background-color: #1d619d;
  position: sticky;
  top: 0px;
  z-index: 1000;
  height: 96px;
}
.navBar {
  height: 96px;
  width: 100%;
  max-width: 1900px;
  margin: 0 auto;
  display: flex;
  place-items: center;
  justify-content: space-between;
  padding-left: 64px;
  padding-right: 64px;
  z-index: 999;
  margin-top: 0px;
}
/* Logo en Header */
.navBar .imageContainer {
  height: 40px;
  flex-shrink: 0;
  margin-right: 40px;
}
.navBar .imageContainer img {
  height: 100%;
}
.navBar .imageContainer a {
  width: 100%;
  height: 100%;
}
/* Links a interno MiDelta */
.navBar > .linkInternal {
  width: 92px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  margin-left: 40px;
  cursor: pointer;
}
.navBar > .linkInternal:hover,
.navBar > .linkInternal.active {
  border-bottom: solid var(--LightBlue) 5px;
}
.navBar > .linkInternal img {
  width: 20px;
  height: 20px;
}
.navBar > .linkInternal p {
  color: #f5f5f5;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
}
/* Links a interno en menu */
.menuScreen .linkInternal img {
  width: 32px;
  height: 32px;
}
.menuScreen .linkInternal {
  display: flex;
  align-items: center;
  padding: 0px 74px;
  background-color: #1d619d;
  width: 100%;
  height: 96px;
  text-decoration: none;
}
.menuScreen .linkInternal p {
  color: #fff;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-left: 12px;
}
@media only screen and (max-width: 1150px) and (min-width: 951px) {
  .navBar {
    padding-left: 32px;
    padding-right: 32px;
  }
  .navBar .imageContainer {
    height: 32px;
  }
}
@media only screen and (max-width: 950px) {
  .showMenuButton {
    height: 32px;
    width: 64px;
  }
  .showMenuButton img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
@media only screen and (max-width: 600px) {
  .navBar {
    padding-left: 40px;
    padding-right: 40px;
  }
  .navBar .imageContainer {
    height: 32px;
    margin-right: 0px;
  }
  .showMenuButton {
    width: 32px;
  }

  /* Links a interno en menu */
  .menuScreen .linkInternal img {
    width: 16px;
    height: 16px;
  }
  .menuScreen .linkInternal {
    padding: 0px 50px;
  }
  .menuScreen .linkInternal p {
    font-size: 20px;
    line-height: 20px;
    margin-left: 4px;
  }
}
@media only screen and (max-width: 380px) {
  .navBar {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (max-width: 350px) {
  .navBar {
    padding-left: 16px;
    padding-right: 16px;
  }
  .showMenuButton {
    height: 28px;
    width: 28px;
  }
  .navBar .imageContainer {
    height: 28px;
  }
  .navBar .imageContainer a {
    height: auto;
  }
}
