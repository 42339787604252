#BROCHURE\ Y\ DOING\ BUSINESS .cardContainer {
  justify-content: space-around;
}
#BROCHURE\ Y\ DOING\ BUSINESS .card {
  height: 384px;
}
#BROCHURE\ Y\ DOING\ BUSINESS .card .imageContainer {
  height: 184px;
}
#BROCHURE\ Y\ DOING\ BUSINESS .card .imageContainer img {
  width: 100%;
  height: 100%;
  border-bottom: 4px solid white;
}
#BROCHURE\ Y\ DOING\ BUSINESS .card .textContainer {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 32px;
  padding-bottom: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
#BROCHURE\ Y\ DOING\ BUSINESS .card .textContainer .name {
  margin-bottom: 25px;
  /* Fix Santi  */
}
#BROCHURE\ Y\ DOING\ BUSINESS .card .textContainer .icon {
  width: 64px;
  height: 64px;
}
@media only screen and (max-width: 1150px) {
  #BROCHURE\ Y\ DOING\ BUSINESS .card {
    height: 384px;
    width: 288px;
  }
  #BROCHURE\ Y\ DOING\ BUSINESS .card .imageContainer {
    height: 184px;
  }
  #BROCHURE\ Y\ DOING\ BUSINESS .card .imageContainer img {
    width: 100%;
    height: 100%;
    border-bottom: 4px solid white;
  }
  #BROCHURE\ Y\ DOING\ BUSINESS .card .textContainer {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 32px;
    padding-bottom: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  #BROCHURE\ Y\ DOING\ BUSINESS .card .textContainer .name {
    margin-bottom: 45px;
  }
}

@media only screen and (max-width: 950px) {
  .AlliesTitle {
    padding-right: 32px;
  }
  #BROCHURE\ Y\ DOING\ BUSINESS .cardContainer {
    gap: 0px;
  }
  #BROCHURE\ Y\ DOING\ BUSINESS .card {
    width: 224px;
    height: 288px;
  }
  #BROCHURE\ Y\ DOING\ BUSINESS .card .imageContainer {
    height: 140px;
  }
  #BROCHURE\ Y\ DOING\ BUSINESS .card .textContainer {
    height: 148px;
  }
  #BROCHURE\ Y\ DOING\ BUSINESS .card .textContainer .name {
    padding-left: 0px;
    padding-right: 0px;
    font-size: 16px;
    line-height: 22px; /* 123.077% */
    margin-bottom: 18px;
  }
  #BROCHURE\ Y\ DOING\ BUSINESS .card .textContainer .icon {
    width: 46px;
    height: 46px;
  }
}

@media only screen and (max-width: 600px) {
  .AlliesTitle {
    text-align: center;
    line-height: 50px; /* 105.263% */
    padding-right: 0px;
  }
  .AlliesText {
    text-align: center;
  }
  #BROCHURE\ Y\ DOING\ BUSINESS .cardContainer {
    width: 361px;
    width: 100%;
    max-width: 450px;
    gap: 64px;
  }
}
