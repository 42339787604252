.mainIndustry {
  height: 772px;
  display: flex;
  padding-bottom: 0px;
}
.mainIndustry .textContainer {
  width: 800px;
  padding-left: 94px;
  padding-right: 64px;
  padding-top: 52px;
  padding-bottom: 52px;
  display: flex;
  flex-direction: column;
}
.mainIndustry .imageContainer {
  width: calc(100% - 800px);
  height: 768px;
}
.mainIndustry .imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mainIndustry .textContainer h1 {
  font-size: 54px;
  font-weight: 500;
  color: var(--DeltaBlue);
  margin-bottom: 32px;
}
.mainIndustry .textContainer > p {
  font-size: 16px;
  line-height: 40px;
  font-weight: 400;
  margin-bottom: 32px;
}
.mainIndustry .textContainer > p:last-of-type {
  flex-grow: 1;
}
.restIndustries {
  padding-left: 96px;
  padding-right: 96px;
}
.restIndustries h3 {
  font-size: 38px;
  font-weight: 500;
  color: var(--DeltaBlue);
  margin-bottom: 64px;
}
.restIndustries .Grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 110px;
  row-gap: 64px;
}

.restIndustries .Grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.restIndustries .Grid img {
  border-radius: 15px;
  margin-bottom: 30px;
}

.restIndustries .Grid p {
  width: 224px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  color: var(--DeltaBlue);
  text-align: center;
}

.restIndustries .Grid div:hover p {
  text-decoration: underline;
}
/* ------ */
.botonIndustrias {
  max-width: 608px;
  height: 64px;
  border-color: var(--DeltaBlue);
  flex-shrink: 0;
}
.botonIndustrias:hover {
  background-color: #95c1d5;
}
.botonIndustrias p {
  color: var(--DeltaBlue);
}
/* ------ */

@media only screen and (max-width: 1250px) {
  .mainIndustry .textContainer {
    width: 576px;
    padding-left: 64px;
  }
  .mainIndustry .imageContainer {
    width: calc(100% - 576px);
  }
  .mainIndustry .textContainer h1 {
    font-size: 38px;
    line-height: 40px;
    margin-bottom: 50px;
  }
  .restIndustries {
    padding-left: 64px;
    padding-right: 64px;
  }
  .restIndustries .Grid {
    row-gap: 64px;
  }
  .botonIndustrias {
    width: 448px;
  }
  .botonIndustrias p {
    font-size: 20px;
  }
}
@media only screen and (max-width: 950px) {
  .mainIndustry {
    height: auto;
    flex-direction: column-reverse;
    padding-bottom: 64px;
    border-bottom: none;
  }
  .mainIndustry .textContainer {
    width: auto;
    padding-left: 64px;
    padding-right: 64px;
    padding-top: 60px;
  }
  .mainIndustry .imageContainer {
    width: auto;
    height: 363px;
  }
  .mainIndustry .textContainer h1 {
    font-size: 48px;
  }
  .mainIndustry .textContainer > p {
    margin-bottom: 40px;
  }
  .mainIndustry + div {
    margin-top: 0;
    margin-bottom: 100px;
  }
  .restList {
    padding-left: 64px;
    padding-right: 64px;
    padding-bottom: 64px;
    list-style-type: none;
  }
  .restList li {
    font-size: 24px;
    color: #364660;
    margin-top: 36px;
  }
  .restList li:hover {
    text-decoration: underline;
  }
  .botonIndustrias {
    width: 100%;
    margin: 0 auto;
    max-width: 640px;
  }
}

@media only screen and (max-width: 600px) {
  .mainIndustry .textContainer {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 40px;
  }
  .mainIndustry .imageContainer {
    height: 256px;
  }
  .mainIndustry .imageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .mainIndustry .textContainer h1 {
    font-size: 30px;
    margin-bottom: 40px;
  }
  .mainIndustry .textContainer > p {
    font-size: 14px;
    line-height: 30px;
    margin-bottom: 24px;
  }
  .restList {
    padding-left: 64px;
    padding-right: 32px;
    padding-bottom: 32px;
  }
  .restList li {
    font-size: 18px;
  }
  .Button {
    max-width: 380px;
  }
}
