@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
:root {
  --DeltaBlue: #1d619d;
  --DarkBlue: #364660;
  --LightBlue: #49c6ff;
  --SMSRed: #a21f4a;
  scroll-behavior: smooth;
}
html {
  margin: 0 auto;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
body {
  background-color: white;
}
.Screen > * {
  max-width: 1900px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 150px;
}
.Screen > .noTop {
  margin-top: 0px;
}
.Screen > .Bottom {
  margin-bottom: 100px;
}
.wide {
  max-width: none;
}
.optionMenuForBig {
  background-color: var(--DarkBlue);
}
.optionMenuForBig > div {
  max-width: 1900px;
  margin-left: auto;
  margin-right: auto;
}
.aboutUsScreen > * {
  margin-top: 0px;
}
.botonComun {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 4px;
  border-radius: 10px;
  text-decoration: none;
}
.botonComun p {
  font-size: 26px;
  font-weight: 600;
}
@media only screen and (max-width: 950px) {
  .botonComun p {
    font-size: 22px;
  }
}
@media only screen and (max-width: 600px) {
  .botonComun p {
    font-size: 14px;
  }
}
