.heroContainer {
  width: 100%;
  height: 768px;
  position: relative;
}
.heroBack {
  display: block;
  width: 100%;
  height: 100%;
}
.heroContainer .textContainer {
  position: relative;
  top: -100%;
  max-height: 768px;
  width: 500px;
  margin-top: 77px;
  margin-left: 92px;
}
.textContainer .heroTitle {
  color: #fff;
  font-size: 54px;
  font-style: normal;
  font-weight: 600;
  line-height: 64px;
  margin-bottom: 54px;
}
.textContainer .heroText {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 39px; /* 162.5% */
  margin-bottom: 64px;
}

@media only screen and (max-width: 950px) {
  #heroButton {
    background-color: var(--DeltaBlue);
  }
  .heroContainer {
    width: 100%;
    height: 736px;
    position: relative;
  }
  .heroContainer .textContainer {
    max-height: 736px;
    width: 530px;
    margin-top: 46px;
    margin-left: 60px;
  }
  .textContainer .heroTitle {
    font-size: 50px;
    line-height: 64px;
    margin-bottom: 54px;
  }
  .textContainer .heroText {
    font-size: 20px;
    line-height: 39px; /* 162.5% */
    margin-bottom: 64px;
  }
}

@media only screen and (max-width: 600px) {
  .heroContainer .textContainer {
    width: 320px;
    height: calc(100% - 54px);
    margin: 0 auto;
    margin-top: 54px;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .textContainer .heroTitle {
    text-align: center;
    font-size: 38px;
    line-height: 52px;
    margin-bottom: 36px;
  }
  .textContainer .heroText {
    text-align: center;
    font-size: 16px;
    line-height: 30px; /* 162.5% */
    margin-bottom: 64px;
  }
  .heroContainer .textContainer a {
    margin-top: auto;
    margin-bottom: 96px;
    margin-left: auto;
    margin-right: auto;
  }
}
/* ------ */
#heroButton:hover {
  background-color: #95c1d5;
}
#heroButton {
  width: 191px;
  height: 64px;
  border-color: #fff;
}
#heroButton p {
  color: #fff;
}
/* ------ */
