.AlliesContainer {
  background: #537d9a;
  padding-bottom: 64px;
}
.AlliesContainer > .textContainer {
  padding-top: 64px;
  padding-left: 192px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 70px;
}
.AlliesTitle {
  color: #fff;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 74.074% */
  margin-bottom: 31px;
}
.AlliesText {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 83.333% */
}
.AlliesContainer .cardContainer {
  width: 1056px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.AlliesContainer .card {
  width: 288px;
  height: 386px;
  border-radius: 15px;
  border: solid white 4px;
  overflow: hidden;
  text-decoration: none;
}
.AlliesContainer .card .textContainer:hover{
  background-color: #95c1d5;
}
.AlliesContainer .card .imageContainer {
  background-color: white;
  height: 186px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.AlliesContainer .card .imageContainer img {
  width: 93%;
}
.AlliesContainer .card .textContainer {
  height: 192px;
  background-color: #537d9a;
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.AlliesContainer .card .textContainer .name {
  color: #fff;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px; /* 123.077% */
  margin-bottom: 28px;
}
.AlliesContainer .card .textContainer .desc {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
}
@media only screen and (max-width: 1150px) {
  .AlliesContainer > .textContainer {
    padding-left: 96px;
  }
  .AlliesContainer .cardContainer {
    width: 896px;
  }
  .AlliesContainer .card {
    width: 256px;
  }
  .AlliesContainer .card .textContainer {
    height: 196px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 30px;
    padding-bottom: 42px;
  }
}

@media only screen and (max-width: 950px) {
  .AlliesContainer > .textContainer {
    padding-left: 64px;
  }
  .AlliesTitle {
    font-size: 38px;
    line-height: 40px; /* 105.263% */
  }
  .AlliesText {
    font-size: 16px;
    line-height: 20px; /* 125% */
  }
  .AlliesContainer .cardContainer {
    width: 576px;
    justify-content: center;
    flex-wrap: wrap;
    gap: 128px;
    row-gap: 50px;
  }
  .AlliesContainer .card {
    width: 224px;
    height: 288px;
  }
  .AlliesContainer .card .imageContainer {
    height: 144px;
  }
  .AlliesContainer .card .textContainer {
    height: 144px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 21px;
  }
  .AlliesContainer .card .textContainer .name {
    padding-left: 39.5px;
    padding-right: 39.5px;
    font-size: 16px;
    line-height: 22px; /* 123.077% */
    margin-bottom: 18px;
  }
  .AlliesContainer .card .textContainer .desc {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    line-height: 20px; /* 157.143% */
  }
}

@media only screen and (max-width: 600px) {
  .AlliesContainer > .textContainer {
    width: 100%;
    max-width: 450px;
    padding-left: 0px;
  }
  .AlliesTitle {
    text-align: center;
    line-height: 50px; /* 105.263% */
  }
  .AlliesText {
    text-align: center;
  }
  .AlliesContainer .cardContainer {
    width: 361px;
    width: 100%;
    max-width: 450px;
  }
}
