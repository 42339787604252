.listScreensHorizontal {
  width: 600px;
  height: 96px;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.listScreensHorizontal div,
.listScreensHorizontal div a {
  cursor: pointer;
  height: 96px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
}
.listScreensHorizontal > div:hover,
.listScreensHorizontal > div.active {
  border-bottom: solid 5px var(--LightBlue);
}
/* Links a paginas en Menu */
.listScreensVertical {
  height: calc(100vh - 192px);
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding-left: 64px;
  padding-right: 64px;
  padding-top: 32px;
}
.listScreensVertical > div {
  height: 84px;
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 2px #fff;

  color: #fff;
  font-size: 26px;
  font-weight: 600;
}
.listScreensVertical div:hover {
  color: var(--DarkBlue);
}
.listScreensVertical div a {
  flex-grow: 1;
  text-decoration: none;
  color: #fff;
}
@media only screen and (max-width: 1150px) and (min-width: 951px) {
  .listScreensHorizontal {
    width: 475px;
  }
  .listScreensHorizontal div a {
    font-size: 12px;
  }
}

@media only screen and (max-width: 600px) {
  /* Links a paginas en Menu */
  .listScreensVertical {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 43px;
    row-gap: 27px;
  }
  .listScreensVertical > div {
    height: 54px;
    font-size: 20px;
  }
}
