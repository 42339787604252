.smsSection,
.ifacSection,
.pane2 {
  margin-top: 0px;
  color: #fff;
}
.smsSection .textContainer {
  background-color: var(--SMSRed);
}
.ifacSection .textContainer {
  background-color: #537d9a;
}
.pane2 .textContainer {
  background-color: var(--DeltaBlue);
  padding-left: 96px;
  padding-right: 96px;
  padding-top: 52px;
  padding-bottom: 52px;
}
.smsSection .textContainer,
.ifacSection .textContainer {
  padding-top: 52px;
  padding-left: 96px;
  padding-bottom: 52px;
}
.smsSection .textContainer .Title,
.ifacSection .textContainer .Title {
  font-size: 50px;
  font-weight: 600;
  margin-bottom: 25px;
}
.smsSection .textContainer .Text,
.ifacSection .textContainer .Text,
.pane2 .textContainer .Text {
  font-size: 24px;
  font-weight: 400;
}
.smsSection .pane1,
.ifacSection .pane1 {
  display: flex;
  padding-left: 96px;
  padding-right: 0px;
  justify-content: space-between;
}
.ifacSection .pane1 {
  padding-right: 96px;
  padding-left: 0px;
}
.smsSection .pane1 .textPane,
.ifacSection .pane1 .textPane {
  width: 608px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 52px;
  padding-top: 32px;
}
.ifacSection .pane1 .textPane {
  padding-top: 52px;
}
.smsSection .pane1 .textPane .TextSMS,
.ifacSection .pane1 .textPane .TextSMS,
.pane2 .TextDelta {
  color: #000;
  font-size: 16px;
  line-height: 40px;
}
.smsSection .pane1 .textPane span {
  display: inline-block;
  width: 180px;
  height: 55px;
  position: relative;
  top: 20px;
  margin-right: 22px;
}
.smsSection .pane1 .textPane span img {
  width: 180px;
  height: 55px;
}
.ifacSection .pane1 .textPane .TextSMS p,
.smsSection .pane1 .textPane .TextSMS p,
.pane2 .TextDelta p {
  margin-top: 24px;
}
/* ------ */
.smsSection .pane1 .textPane .smsLink,
.ifacSection .pane1 .textPane .ifacLink {
  height: 64px;
  margin-top: 30px;
}
.smsSection .pane1 .textPane .smsLink {
  border-color: var(--SMSRed);
}
.ifacSection .pane1 .textPane .ifacLink {
  border-color: #537d9a;
}
.smsSection .pane1 .textPane .smsLink:hover {
  background-color: #c5c5c5;
}
.ifacSection .pane1 .textPane .ifacLink:hover {
  background-color: #95c1d5;
}
.smsSection .pane1 .textPane .smsLink p {
  color: var(--SMSRed);
}
.ifacSection .pane1 .textPane .ifacLink p {
  color: #537d9a;
}
/* ------ */
.smsSection .pane1 > a {
  width: calc(100% - 608px - 64px);
  max-width: 1000px;
  height: 768px;
  display: block;
}
.smsSection .pane1 img {
  width: 100%;
  height: 100%;
}
.ifacSection .pane1 img {
  width: calc(100% - 608px - 64px);
  max-width: 1000px;
  height: 768px;
}
.ifacSection .pane1 .textPane span {
  display: inline-block;
  width: 179px;
  height: 32px;
  margin-right: 22px;
  position: relative;
  top: 2px;
}
.ifacSection .pane1 .textPane span img {
  width: 179px;
  height: 32px;
}
.pane2 {
  margin-bottom: 52px;
}
.pane2 h2 {
  font-size: 48px;
  color: #fff;
  margin-bottom: 25px;
  background-color: var(--DeltaBlue);
}
.pane2 .TextDelta {
  padding-left: 96px;
  padding-right: 96px;
  padding-top: 52px;
}
.pane2 .TextDelta span {
  width: 173px;
  height: 29px;
  margin-right: 22px;
  position: relative;
  top: 9px;
}
.pane2 .TextDelta span img {
  width: 173px;
  height: 29px;
}

@media only screen and (max-width: 1300px) {
  .smsSection .textContainer,
  .ifacSection .textContainer {
    padding-left: 64px;
  }
  .smsSection .pane1 {
    padding-left: 64px;
  }
  .ifacSection .pane1 {
    padding-right: 64px;
  }
  .smsSection .pane1 .textPane,
  .ifacSection .pane1 .textPane {
    width: 480px;
  }
  .smsSection .pane1 > a {
    width: calc(100% - 64px - 480px);
    height: 100%;
  }
  .ifacSection .pane1 img {
    width: calc(100% - 64px - 480px);
    height: 100%;
  }
  .pane2 .textContainer {
    padding-left: 64px;
    padding-right: 64px;
  }
  .pane2 .TextDelta {
    padding-left: 64px;
    padding-right: 64px;
  }
  .pane2 h2 {
    font-size: 48px;
    margin-bottom: 46px;
  }
}
@media only screen and (max-width: 950px) {
  .smsSection .textContainer,
  .ifacSection .textContainer {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .smsSection .textContainer .Title,
  .ifacSection .textContainer .Title {
    font-size: 48px;
  }
  .smsSection .pane1,
  .ifacSection .pane1 {
    padding-left: 0;
    padding-right: 0;
    height: auto;
  }
  .smsSection .pane1 {
    flex-direction: column-reverse;
  }
  .ifacSection .pane1 {
    flex-direction: column;
  }
  .smsSection .pane1 .textPane,
  .ifacSection .pane1 .textPane {
    width: 100%;
    padding-left: 64px;
    padding-right: 64px;
  }
  .smsSection .pane1 > a {
    width: 100%;
    height: 375px;
  }
  .ifacSection .pane1 img {
    width: 100%;
    height: 425px;
  }
  .pane2 h2 {
    font-size: 38px;
    margin-bottom: 42px;
  }
}

@media only screen and (max-width: 600px) {
  .smsSection .textContainer,
  .ifacSection .textContainer {
    padding-top: 64px;
    padding-left: 0px;
    padding-bottom: 52px;
  }
  .smsSection .textContainer .Title,
  .ifacSection .textContainer .Title {
    font-size: 38px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 32px;
  }
  .smsSection .textContainer .Text,
  .ifacSection .textContainer .Text {
    text-align: center;
    font-size: 16px;
    line-height: 20px;
  }
  .smsSection .pane1 .textPane,
  .ifacSection .pane1 .textPane {
    padding-left: 32px;
    padding-right: 32px;
  }
  .smsSection .pane1 .textPane .TextSMS,
  .ifacSection .pane1 .textPane .TextSMS,
  .pane2 .TextDelta {
    font-size: 12px;
    line-height: 30px;
  }
  .smsSection .pane1 .textPane span {
    width: 126px;
    height: 39px;
    top: 14px;
    margin-right: 16px;
  }
  .smsSection .pane1 .textPane span img {
    width: 126px;
    height: 39px;
  }
  .ifacSection .pane1 .textPane .TextSMS p {
    margin-top: 0px;
  }
  .smsSection .pane1 > a {
    height: 320px;
  }
  .ifacSection .pane1 img {
    height: 320px;
  }
  .ifacSection .pane1 .textPane span {
    width: 160px;
    height: 29px;
    margin-right: 16px;
  }
  .ifacSection .pane1 .textPane span img {
    width: 160px;
    height: 29px;
  }
  .pane2 .TextDelta {
    padding-left: 32px;
    padding-right: 32px;
  }

  .pane2 h2 {
    font-size: 38px;
    line-height: 40px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 60px;
  }
  .pane2 .TextDelta span {
    width: 154px;
    height: 26px;
    margin-right: 16px;
    top: 7px;
  }
  .pane2 .TextDelta span img {
    width: 154px;
    height: 26px;
  }
}
