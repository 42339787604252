.bigOfficeContainer > p {
  font-weight: 500;
  font-size: 48px;
  line-height: 40px;
  color: var(--DeltaBlue);
  margin-left: 96px;
  margin-top: 64px;
}
.contentFlex {
  display: flex;
  padding-left: 96px;
  padding-right: 96px;
  padding-top: 64px;
  padding-bottom: 64px;
  border-bottom: #d9d9d9 4px solid;
}
.officeDataContainer {
  height: 320px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
}
.cnb {
  width: 390px;
}
.cnb a {
  text-decoration: none;
  width: 390px;
  display: flex;
}
.cnb a p {
  color: var(--DeltaBlue);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 100% */
  text-decoration: none;
}
.cnb a:hover p {
  text-decoration: underline;
}
.cnb a .icon {
  width: 32px;
  height: 32px;
  margin-right: 18px;
  align-self: flex-start;
}
.embedMap {
  width: 608px;
  height: 320px;
  flex-shrink: 0;
}
.embedMap iframe {
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 1200px) {
  .contactSection .sectionText {
    padding-left: 64px;
  }
  .bigOfficeContainer > p {
    margin-left: 64px;
  }
  .contentFlex {
    padding-left: 64px;
    padding-right: 64px;
  }
  .embedMap {
    width: 448px;
  }
}
@media only screen and (max-width: 960px) {
  .bigOfficeContainer > p {
    font-size: 38px;
    margin-left: 64px;
  }
  .officeDataContainer {
    width: 258px;
  }
  .cnb {
    width: 258px;
  }
  .cnb a {
    width: 258px;
  }
  .cnb a p {
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .embedMap {
    width: 312px;
  }
}
@media only screen and (max-width: 715px) {
  .contactSection .sectionText {
    padding-left: 32px;
    padding-right: 32px;
  }
  .contactSection .sectionText > h2,
  .contactSection .sectionText > h1 {
    font-size: 38px;
    text-align: center;
  }
  .contactSection .sectionText > p {
    font-weight: 400;
    margin-bottom: 40px;
    text-align: center;
  }

  .contactSection .bigOfficeContainer > p {
    margin-left: 0px;
    font-size: 38px;
    line-height: 40px;
    text-align: center;
  }
  .contentFlex {
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 32px;
    gap: 40px;
  }
  .officeDataContainer {
    height: auto;
    width: 100%;
    gap: 32px;
  }
  .cnb {
    width: auto;
    margin: auto;
  }
  .cnb a {
    width: 270px;
  }
  .cnb a p {
    font-size: 16px;
  }
  .cnb a .icon {
    width: 26px;
    height: 26px;
  }
  .embedMap {
    width: auto;
    height: 224px;
  }
}
