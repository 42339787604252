/* Styles for the entire submenu */
.optionMenu {
  margin-top: 0px;
  min-height: 448px;
  background-color: var(--DarkBlue);
  display: flex;
}
/* section with the description */
.optionMenu .desc {
  width: calc(100% - 802px);
  padding-top: 70px;
  padding-left: 94px;
  margin-bottom: 32px;
}
.optionMenu .desc h2 {
  font-size: 35px;
  line-height: 40px;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 35px;
}
.optionMenu .desc p {
  width: 333px;
  font-size: 15px;
  line-height: 32px;
  color: white;
}
/* section with the links */
.optionMenu .links {
  padding-top: 32px;
  width: 386px;
  margin-bottom: 32px;
}
.optionMenu .links > div {
  height: 64px;
  border-top: solid 2px #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 64px;
  padding-right: 32px;
}
.optionMenu .links > div:last-child {
  height: 66px;
  border-bottom: solid 2px #ffffff;
}
.optionMenu .links > div {
  font-size: 16px;
  color: #ffffff;
}
.optionMenu .links > div img{
  transition: 0.5s;
}
.optionMenu .links > div:hover,
.optionMenu .links > .active {
  background-color: #537d9a;
  border-left: 8px solid var(--LightBlue);
  padding-left: 56px;
  border-top: none;
}
.optionMenu .links .active img {
  transform: rotate(180deg);
}
/* sub menu */
.optionMenu .subMenu {
  width: 416px;
  background-color: #537d9a;
  padding-left: 64px;
  padding-top: 34px;
}
.optionMenu .subMenu div {
  height: 64px;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}
.optionMenu .subMenu div:hover {
  text-decoration: underline;
}
.optionMenu .filler {
  width: 386px;
}
@media only screen and (max-width: 1250px) {
  /* section with the description */
  .optionMenu .desc {
    width: calc(100% - 630px);
    padding-left: 50px;
  }
  .optionMenu .desc h2 {
    font-size: 32px;
    margin-bottom: 43px;
  }
  .optionMenu .desc p {
    width: 265px;
    font-size: 14px;
  }
  /* section with the links */
  .optionMenu .links {
    width: 352px;
  }
  .optionMenu .links > div {
    font-size: 14px;
  }
  /* sub menu */
  .optionMenu .subMenu {
    padding-left: 32px;
    width: 288px;
  }
  .optionMenu .subMenu div {
    font-size: 13px;
  }
  .optionMenu .filler {
    width: 288px;
  }
}
