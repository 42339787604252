.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #364660;
  width: 100%;
  overflow: hidden;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 64px;
}
.footerContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 265px repeat(3, 230px);
  grid-template-rows: 35px 125px 64px;
  grid-template-areas:
    "a b c d"
    "e b c d"
    "f b c d";
  row-gap: 45px;
  margin-bottom: 0px;
  justify-content: space-around;
}
.officeContainer {
  width: 230px;
  min-height: 230px;
  flex-shrink: 0;
}
.footerContainer .logoFooter {
  width: 256px;
  height: 33px;
}
.footerContainer .logoFooter img {
  width: 100%;
}
.footer .contactDescription {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
.footer .logos {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 32px;
}
.footer .logos a:hover{
  background-color: #537d9a;
}
.footer .logos a {
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #4a5970;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer .logos a img {
  width: 36px;
  height: 36px;
}
.officeContainer .city {
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  margin-bottom: 37px;
  margin-top: 27px;
}
.cn {
  margin-bottom: 30px;
}
.cn:last-child {
  margin-bottom: 0px;
}
.cn a .icon {
  width: 20px;
  height: 20px;
  margin-right: 20px;
  align-self: flex-start;
}
.cn a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
}
.cn a:hover p, .linkAdd:hover{
  text-decoration: underline;
}
.footer p, .linkAdd{
  text-decoration: none;
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.derechos {
  margin-bottom: 55px;
}
.footer .derechos p {
  text-align: center;
}

@media only screen and (max-width: 1350px) {
  .footerContainer {
    justify-content: space-between;
  }
}
@media only screen and (max-width: 1150px) {
  .footerContainer {
    grid-template-columns: 64px repeat(3, 230px);
  }
}

@media only screen and (max-width: 1000px) {
  .footerContainer {
    width: 100%;
    grid-template-columns: 256px min(calc(100% - 276px), 288px);
    grid-template-rows: 72px 95px;
    grid-template-areas:
      "a b"
      "c d";
    gap: 20px;
    row-gap: 15px;
    margin-bottom: 58px;
  }
  .footerContainer .logoFooter {
    width: 256px;
    height: 33px;
  }
  .footerContainer .logoFooter img {
    width: 100%;
  }
  .footer .contactDescription {
    font-size: 12px;
    line-height: 27px;
  }
  .footer .logos {
    width: 100%;
    height: 64px;
    grid-area: d;
    align-self: flex-end;
  }
  .linkContact {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 256px;
    height: 95px;
    flex-shrink: 0;
    background-color: rgba(198, 189, 189, 0.22);
    border-radius: 20px;
  }
  .footer .linkContact a{
    text-decoration: none;
  }
  .footer .linkContact p {
    color: #fff;
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
  }
  .footer p {
    font-size: 12px;
    line-height: 27px;
  }
}
@media only screen and (max-width: 600px) {
  .footer {
    padding-left: 64px;
    padding-right: 64px;
    padding-top: 64px;
  }
  .footerContainer {
    width: 100%;
    max-width: 320px;
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    margin-bottom: 57px;
  }
  .footerContainer .logoFooter {
    width: 100%;
    height: auto;
  }
  .footer .contactDescription {
    font-size: 12px;
    line-height: 27px;
  }
  .derechos {
    margin-bottom: 64px;
  }
  .footer .logos {
    width: 100%;
    height: 64px;
    grid-area: d;
    align-self: flex-end;
  }

  .linkContact {
    width: 100%;
    height: 128px;
  }
  .footer .linkContact p {
    font-size: 20px;
  }
}
@media only screen and (max-width: 384px) {
  .footer {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 64px;
  }
}
