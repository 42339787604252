.statsContainer {
  width: 1056px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 160px repeat(3, 206px);
  align-items: center;
  justify-content: space-between;
  gap: 92px;
  row-gap: 30px;
  margin-bottom: 64px;
}
.delta {
  --mainBlue: #1d619d;
}
.sms {
  margin-top: 0px;
  --mainBlue: #8d264b;
}
.statLogo {
  width: 160px;
}
.stat {
  width: 200px;
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stat .iconStat {
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
}
.titleStat {
  color: var(--mainBlue);
  text-align: center;
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: 35px; /* 77.778% */
  margin-bottom: 30px;
}
.textStat {
  color: var(--mainBlue);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600; /* Ultimos cambios -------------------------------------------------------------------------------------*/
  line-height: 39px;
}
.divisor {
  width: 1056px;
  height: 4px;
  background-color: #c4c4c4;
}
.sms .divisor {
  display: none;
}

@media only screen and (max-width: 1150px) {
  .statsContainer {
    width: 896px;
    gap: 39px;
    row-gap: 30px;
  }
  .divisor {
    width: 896px;
  }
}

@media only screen and (max-width: 900px) {
  .statsContainer {
    width: 640px;
    grid-template-columns: 112px repeat(3, 165px);
    gap: 11px;
    row-gap: 36px;
    margin-bottom: 56px;
  }
  .statLogo {
    width: 98px;
  }
  .stat {
    width: 165px;
    height: 240px;
  }
  .titleStat {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  .textStat {
    font-size: 16px;
    line-height: 30px;
  }
  .divisor {
    width: 640px;
  }
  .sms .divisor {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .statsContainer {
    width: 320px;
    grid-template-columns: repeat(2, 155px);
    grid-template-rows: 250px 49px 250px;
    grid-template-areas:
      "a b"
      "c c"
      "d e";
    gap: 5px;
    row-gap: 0px;
    margin-bottom: 0px;
    place-items: center;
  }
  .sms {
    margin-top: 0px;
  }
  .stat {
    width: 155px;
    height: 250px;
  }
  .stat .iconStat {
    width: 64px;
    height: 64px;
    margin-bottom: 30px;
  }
  .textStat {
    font-size: 15px;
    line-height: 28px;
  }
  .divisor {
    margin-top: 5px;
    margin-bottom: 40px;
    grid-area: c;
    width: 320px;
    height: 4px;
    background-color: #c4c4c4;
  }
  .sms .divisor {
    display: block;
  }
}
